import React from "react";

export default function FooterAlt() {
  return (
    <div className="footer-alt2">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="text-center text-white-50 footer-alt2 py-3">
              <p className="mb-0">
                © YẾN SÀO HÒA LÊ - SỨC KHỎE CHO MỌI GIA ĐÌNH -{" "}
                <a
                  href="#"
                  target="_blank"
                  className="text-reset text-decoration-underline"
                >
                  ANNK'S TEAM
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
