import React from 'react'
import FooterAlt from './components/footeralt'

export default function Footer() {
  return (
    <>
      {/* <Footer1/> */}
      <FooterAlt/>
    </>
  )
}
