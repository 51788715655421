import React from "react";
import Banner1 from "./components/banner1";
import Banner2 from "./components/banner2";

export default function Banner() {
  return (
    <>
      <Banner1 />
      <Banner2 />
    </>
  );
}
